export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869832/general/yelp_white.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1631607191/2scoops/Group_608_1.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869833/general/zomato_white.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1631607190/2scoops/Group_609.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582589981/Kaido/viewMenu.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1591377852/general/cflogo_black.png'
export const about =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632768906/ohanapoke/about2.jpg'
export const contactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632769117/ohanapoke/contactBg.jpg'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632769383/ohanapoke/heroBg.jpg'
export const heroText =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1631656571/2scoops/heroText.png'
export const logo =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632767767/ohanapoke/logo.png'
export const locationMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632769117/ohanapoke/locationMap.jpg'
export const mobileAbout =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632771427/ohanapoke/mobileAbout2.jpg'
export const mobileContactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632773231/ohanapoke/mobileContactbg.jpg'
export const mobileHero =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632769434/ohanapoke/mobileHero.jpg'
export const mobileMap =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632773286/ohanapoke/mobileMap.jpg'
export const mobileHeroText =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1631604558/2scoops/Now_Taking_Online_Orders_1.png'
export const mobilePromotion =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632771259/ohanapoke/mobilePromo.jpg'
export const orderPickupButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632768100/ohanapoke/orderPickupButton.png'
export const promotions =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632768257/ohanapoke/promotions.jpg'
export const foodGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632772782/ohanapoke/foodGallery.jpg'
export const mobileGallery =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632771593/ohanapoke/mobileGallery.jpg'
export const aboutDivider =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632768907/ohanapoke/about1.jpg'
export const mobileDivider =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632771426/ohanapoke/mobileAbout1.jpg'
export const gallery1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632772782/ohanapoke/gallery2.jpg'
export const gallery2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632772782/ohanapoke/gallery3.jpg'
export const gallery3 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632772782/ohanapoke/gallery4.jpg'
export const gallery4 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1632772782/ohanapoke/gallery5.jpg'
