import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText, logo } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100vh"
          w="100%"
          image={`url(${mobileHero}) center / cover no-repeat`}
          column
          alignCenter
        >
          <CFView textCenter w="100%">
            <CFView mt="50px">
              <CFImage
                w="95%"
                src={logo}
                alt="Ohana Poke hero text"
                zIndex={98}
              />

              <CFView
                m="0 auto"
                w="90%"
                xBold
                mt="25px"
                style={{ lineHeight: '32px', fontSize: 28 }}
              >
                Welcome to Ohana Poke! We are now taking online orders!
              </CFView>
            </CFView>
            <CFView mt="25px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) 40% 0% / cover no-repeat`}
          boxShadow="1px 1px 3px rgba(0,0,0,.6)"
          zIndex={90}
          column
          justifyBetween
          alignStart
        >
          <CFView column justifyCenter alignStart ml="80px" h="650px">
            <CFView column justifyCenter alignStart>
              <CFImage
                w="35%"
                minWidth="450px"
                src={logo}
                alt="Ohana Poke hero text"
                zIndex={98}
              />
              <CFView
                w="45%"
                mt="35px"
                style={{ lineHeight: '32px', fontSize: 25 }}
              >
                Welcome to Ohana Poke, where our mission is to present to you
                an unforgettable healthy experience, filled with fresh and
                delicious Hawaiian and Japanese style cuisines.
              </CFView>
            </CFView>
            <CFView mt="40px" pulsate>
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
