import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout, aboutDivider, mobileDivider } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center mt="-5px">
          <CFImage
            src={mobileDivider}
            w="100%"
            alt="Ohana Poke About"
            style="display: block"
          />
          <CFImage
            src={mobileAbout}
            w="100%"
            alt="Ohana Poke About"
            style="display: block"
          />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column m="0 auto">
          <CFView w="100%" maxWidth="2000px">
            <CFImage
              src={aboutDivider}
              w="100%"
              alt="Promotions"
              style="display: block"
            />
            <CFImage
              src={about}
              w="100%"
              alt="Promotions"
              style="display: block"
            />
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
