import React from 'react'
import {
  CFImage,
  CFLink,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { yelp, facebook, instagram, zomato } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView mv="20px" column justifyStart>
          <CFView h3 futura bold color="#22AA70" mb="20px">
            SOCIAL MEDIA
          </CFView>
          <CFView row center color="#58595B" bold>
            {/* <CFLink
              target="_blank"
              href="https://www.facebook.com/2scoops-797412177310322/"
            >
              <CFImage
                w="50px"
                pr="10px"
                src={facebook}
                alt="Ohana Poke Facebook"
              />
            </CFLink> */}
            <CFLink
              target="_blank"
              href="https://www.instagram.com/ohanapoke_victoria/?hl=en"
            >
              <CFImage
                w="50px"
                pr="10px"
                src={instagram}
                alt="Ohana Poke Instagram"
              />
            </CFLink>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView mb="13px" mt="15px" column justifyStart>
          <CFView h3 futura bold color="#22AA70" mb="15px">
            SOCIAL MEDIA
          </CFView>
          <CFView row justifyStart alignCenter color="#58595B" bold>
            {/* <CFLink
              target="_blank"
              href="https://www.facebook.com/2scoops-797412177310322/"
            >
              <CFImage
                w="45px"
                pr="10px"
                src={facebook}
                alt="Ohana Poke Facebook"
                hover
              />
            </CFLink> */}
            <CFLink
              target="_blank"
              href="https://www.instagram.com/ohanapoke_victoria/?hl=en"
            >
              <CFImage
                w="45px"
                pr="10px"
                src={instagram}
                alt="Ohana Poke Instagram"
                hover
              />
            </CFLink>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
