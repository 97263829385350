import React from 'react'
import { CFView, CFImage, DefaultScreen, MobileScreen } from 'components'
import {
  foodGallery,
  mobileGallery,
  gallery1,
  gallery2,
  gallery3,
  gallery4,
} from 'images'
import Slider from 'react-slick'

const settings = {
  infinite: true,
  speed: 500,
  arrows: false,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  cssEase: 'linear',
  pauseOnHover: false,
  draggable: true,
  swipe: true,
}

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobileGallery} w="100%" alt="Food Gallery" />
        </CFView>
        <Slider {...{ ...settings, slidesToShow: 1 }}>
          <CFImage
            src={gallery1}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pb="10px"
          />
          <CFImage
            src={gallery2}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pb="10px"
          />
          <CFImage
            src={gallery3}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pb="10px"
          />
          <CFImage
            src={gallery4}
            style={{ objectFit: 'contain' }}
            ph="10px"
            pb="10px"
          />
        </Slider>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="2000px">
          <CFImage src={foodGallery} w="100%" alt="Food Gallery" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
